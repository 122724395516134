import React, { Component } from 'react';

import Menubar from '../common/Menubar';
import ProjectsGrid from '../common/ProjectsGrid';
import LandingPage from '../help/LandingPage';
import About from '../about/About';

export default class LabelHome extends Component {
  render() {
    return (
      <Menubar active="home">
        <LandingPage></LandingPage>
      </Menubar>
    );
  }
}
