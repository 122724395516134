import React, { Component } from 'react';
import DocumentMeta from 'react-document-meta';
import { Link } from 'react-router-dom';
import styles from './aboutstyle.css';

import { Header, List, Icon, Label, Segment, Flag } from 'semantic-ui-react';

import foerderlogo from './images/BMDV_Fz_2021_WebSVG_de.svg';
import adfclogo from './images/ADFC-Logo_2009_1.svg';
import hlrslogo from './images/hlrslogo.png';
import kitlogo from './images/kitlogo.svg';
import caperevisologo from './images/CapeRevisoLogo.png';
import projekt3logo from './images/BicycleLabeling.png';
import projekt5logo from './images/RecumbentBikeLabeling.png';

function Img({ src, caption }) {
  return (
    <figure style={{ textAlign: 'center', margin: '1em 1em' }}>
      <img src={src} alt={caption} style={{ width: '70%' }} />
      <figcaption style={{ color: '#999', fontSize: '12px' }}>
        {caption}
      </figcaption>
    </figure>
  );
}

function Img2({ src, caption }) {
  return (
    <figure>
      <img src={src} alt={caption} style={{ width: '25%' }} />
      <figcaption style={{ color: '#999', fontSize: '12px' }}>
        {caption}
      </figcaption>
    </figure>
  );
}

function ImgRight({ src, caption }) {
  return (
    <figure>
      <img align="right" src={src} alt={caption} style={{ width: '25%' }} />
      <figcaption style={{ color: '#999', fontSize: '12px' }}>
        {caption}
      </figcaption>
    </figure>
  );
}

function Img3({ src1, src2, src3, caption }) {
  return (
    <figure>
      <img
        src={src1}
        alt={caption}
        style={{ width: '32%', border: '15px solid transparent' }}
      />
      <img
        src={src2}
        alt={caption}
        style={{ width: '32%', border: '15px solid transparent' }}
      />
      <img
        src={src3}
        alt={caption}
        style={{ width: '32%', border: '15px solid transparent' }}
      />
      <figcaption style={{ color: '#999', fontSize: '12px' }}>
        {caption}
      </figcaption>
    </figure>
  );
}

function Video({ src, caption }) {
  return (
    <figure style={{ textAlign: 'center', margin: '1em 1em' }}>
      <video
        alt={caption}
        autoPlay
        controls
        loop
        muted
        style={{ maxWidth: '100%', border: '1px solid #AAA' }}
      >
        <source src={src} type="video/ogg" />
      </video>
      <figcaption style={{ color: '#999', fontSize: '12px' }}>
        {caption}
      </figcaption>
    </figure>
  );
}

export default class About extends Component {
  render() {
    return (
      <div
        style={{
          flex: 1,
          paddingBottom: '5em',
          margin: '4rem auto',
          maxWidth: '60rem',
        }}
      >
        <CapeRevisoLogo />
        <Header as="h1">Über CapeReviso und dieses Tool</Header>
        <Header as="h2">
          <Link to="/legal-info" target="_blank">
            Impressum
          </Link>
        </Header>
        <AboutCapeReviso />
        <AboutLabelingTool />
        <Foerderlogo />
      </div>
    );
  }
}

export class CapeRevisoLogo extends Component {
  render() {
    return (
      <div>
        <Img src={caperevisologo} caption="" />
      </div>
    );
  }
}

export class Foerderlogo extends Component {
  render() {
    return (
      <div>
        <ImgRight src={foerderlogo} caption="" />
      </div>
    );
  }
}

export class AboutLabelingTool extends Component {
  render() {
    return (
      <div>
        <Header as="h2">Labeling Tool</Header>
        <p>
          Während es umfassende Datensätze, vortrainierte neuronale Netze und
          Trainingsdaten für die Erkennung von Kraftfahrzeugen und Menschen
          gibt, stoßen diese jedoch bei Verkehrsteilnehmerr*innen der
          Mikromobilität an Grenzen. Rollatoren, Rollstühle, Kinderanhänger,
          Lastenräder und ähnliche werden nicht sicher genug erkannt. Diese
          Lücke soll mit Hilfe dieses Labeling-Tools geschlossen werden. Den
          Nutzer*innen werden Bilddaten präsentiert die sie mit ihrem Wissen
          kategorisieren und dieses Wissen für zukünftige Bilderkennungen mit
          neuronalen Netzen verfügbar machen.
        </p>
        <p>
          Die Software ist eine leicht angepasste Version des{' '}
          <a href="https://github.com/Slava/label-tool">
            Label Tool von Kim Slava
          </a>
        </p>
      </div>
    );
  }
}

export class AboutCapeReviso extends Component {
  render() {
    return (
      <div>
        <Header as="h2">CapeReviso</Header>
        <p>
          Zur Förderung von Fuß- und Radverkehr zielt das Projekt CapeReviso auf
          eine Reduktion von Konflikten und Stress. Dabei werden digitale Tools
          wie Machine Learning, Sensornetzwerke, digitale Zwillinge, Virtuelle
          Realität und Living Labs erforscht und zu einem neuartigen Werkzeug
          für die Stadt-und Verkehrsplanung zusammengeführt.
        </p>
        <p>
          Projektpartner*innen sind der ADFC e.V., das
          Höchstleistungsrechenzentrum Stuttgart und das Karlsruher Institut für
          Technologie. Die Stadt Stuttgart ist über das Amt für Stadtplanung und
          Wohnen assoziierte Partnerin des Projektes.&nbsp;
          <Link to="/capereviso-blog" target="_blank">
            Mehr...
          </Link>
          <Img3 src1={adfclogo} src2={hlrslogo} src3={kitlogo} caption="" />
        </p>
      </div>
    );
  }
}

export class AboutDeinBeitrag extends Component {
  render() {
    return (
      <div>
        <Header as="h2">Dein Beitrag</Header>
        <p>
          Mit diesem Tool kannst du einen Beitrag leisten, damit schwache
          Verkehrsteilnehmer*innen noch besser von neuronalen Netzen
          automatisiert erkannt werden. So können zum Beispiel ihre Wege und der
          Platz, den sie brauchen ermittelt werden. Dazu bringst du dein Wissen
          über die unterschiedlichen Verkehrsteilnehmer*innen ein und markierst
          und kategorisierst diese auf von uns vorbereiteten Bildern. Bevor du
          loslegst, lies dir bitte unsere{' '}
          <Link to="/help_de" target="_blank">
            Hilfeseite{' '}
          </Link>
          durch, damit alle nach den gleichen Regeln markieren.
        </p>
      </div>
    );
  }
}

export class Projekt3Logo extends Component {
  render() {
    return (
      <div>
        <Img2
          src={projekt3logo}
          caption="Labeln starten, alle Räder und Fußgänger*innen"
        />
      </div>
    );
  }
}

export class Projekt5Logo extends Component {
  render() {
    return (
      <div>
        <Img2
          src={projekt5logo}
          caption="Schnelles Labeln: Nur besondere Fahrzeuge, keine Menschen oder normale Räder"
        />
      </div>
    );
  }
}
