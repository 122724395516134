import React, { Component } from 'react';
import DocumentMeta from 'react-document-meta';
import { Link } from 'react-router-dom';

import { Header, List, Icon, Label, Segment, Flag } from 'semantic-ui-react';

import Menubar from '../common/Menubar';

import areasVideo from './tutorial/videos/bike-and-cyclist.ogv';
import pointsVideo from './tutorial/videos/ground-points.ogv';
import visibilityVideo from './tutorial/videos/visibility.ogv';

function Img({ src, caption }) {
  return (
    <figure style={{ textAlign: 'center', margin: '1em 1em' }}>
      <img src={src} alt={caption} />
      <figcaption style={{ color: '#999', fontSize: '12px' }}>
        {caption}
      </figcaption>
    </figure>
  );
}

function Video({ src, caption }) {
  return (
    <figure style={{ textAlign: 'center', margin: '1em 1em' }}>
      <video
        alt={caption}
        autoPlay
        controls
        loop
        muted
        style={{ maxWidth: '100%', border: '1px solid #AAA' }}
      >
        <source src={src} type="video/ogg" />
      </video>
      <figcaption style={{ color: '#999', fontSize: '12px' }}>
        {caption}
      </figcaption>
    </figure>
  );
}

export default class Help_De extends Component {
  render() {
    return (
      <Menubar active="help">
        <DocumentMeta title="Help -- Label Tool">
          <div
            style={{
              flex: 1,
              paddingBottom: '5em',
              margin: '4rem auto',
              maxWidth: '60rem',
            }}
          >
            <Link to="/help_de">
              <button>
                <Flag name="de" /> Deutsch
              </button>
            </Link>
            <Link to="/help">
              <button>
                <Flag name="gb" /> English
              </button>
            </Link>
            <Header as="h1">Darum geht es</Header>
            <p>
              Im CapeReviso-Labelingtool können Nutzer*innen Objekte mit
              Markierungen versehen. Je nach Modus sollen
              Verkehrsteilneher*innen und/oder Fahrzeuge markiert werden.
              Klassische PKW oder LKW (alle KFZ im Sinne der StVO) sind immer
              ausgenommen. Diese Objekte sind stets im rechten Bereich auf einem
              Bild zu sehen. Links neben dem Bild befindet sich stets eine Liste
              mit zur Auswahl stehenden Objekttypen. Alle Objekte, die im Bild
              zu sehen sind, sollen markiert werden, egal ob stehend oder in
              Bewegung.
            </p>

            <p>
	    Hinweis: Die in dieser Hilfe enthaltenen Videos funktionieren nicht 
	    in Safari, an einer Lösung wird gearbeitet. Bitte auf andere Browser
	    wie Firefox oder Chromium ausweichen.
            </p>
            <Header as="h2">Modus Schnelles Labeln</Header>
            <p>
              In diesem Modus sollen nur alle besonderen Fahrzeuge (Lastenräder,
              Liegeräder, Kinderanhänger, etc.) markiert werden. Normale
              Fahrräder und alle Menschen, egal ob sie zu Fuß gehen oder fahren,
              sind ausgenommen. Deshalb gibt es auf einigen Bilder nichts zu
              markieren und du kannst mit einem Haken bei "Hier gibt es nichts
              zu tun!" und einem Klick auch "Next" zum nächsten Bild kommen.
            </p>
            <Header as="h2">Modus Ausführliches Labeln</Header>
            <p>
              In diesem Modus sollen alle Arten von Fahrrädern, ihre
              Fahrer*innen und auch Fußgänger*innen markiert werden. Das kann
              bei einigen Bilder ganz schön viel Arbeit sein. Wenn du das nicht
              vollständig machst, ist es nicht schlimm und die gemachten
              Markierungen liefern trotzdem einen Beitrag. Du kannst dann einen
              Haken bei "Ich habe das Bild nicht oder nur teilweise bearbeitet"
              setzen und mit "Next" zum nächsten gehen.
            </p>
            <Header as="h2">Zu viele Objekte?</Header>
            <p>
              Wenn dir das Bild zu voll ist, muss es nicht gelabeled werden. Du
              entscheidest, ob du das Bild bearbeiten möchtest oder mit einem
              Haken bei "Es waren mir zu viele Objekte im Bild" in der
              Mini-Umfrage versiehst und auf "Next" klickst.
            </p>

            <Header as="h2">Geforderte Genauigkeit</Header>
            <p>
              Die Objektmarkierungen müssen nicht pixelgenau erfolgen. Als
              Orientierung können die in dieser Hilfe eingefügten Videos
              genommen werden. Es sollte jedoch immer das ganze Objekt und nicht
              nur ein Teil markiert werden.
            </p>

            <Header as="h2">Objekttyp auswählen</Header>
            <p>
              Jedem Objekttyp ist ein Hotkey auf der Tastatur (Zahlen 0-9 und
              Buchstaben A-Z) und eine Farbe zugewiesen. Durch Drücken des
              Hotkeys oder mit einem Klick auf das Label wird der Zeichenmodus
              für dieses Label aktiviert.
            </p>

            <Header as="h2">Eine Objektmarkierung hinzufügen</Header>
            <p>
              Um zu beginnen, auf eine der Kategorien links klicken, die als
              Objektmarkierungen gekennzeichnet sind.
              <Icon name="object ungroup outline" />.
            </p>

            <p>
              Rechteck im Bild durch Klicken von zwei gegenüberliegenden Punkten
              anlegen. Die Größe des Rechtecks sollte möglichst genau den Maßen
              des Objekts entsprechen, wie im Video zu sehen ist.
            </p>

            <p>
              Zum Editieren auf die Umrandung klicken. Diese wird dann
              gestrichelt angezeigt und einzelne Punkte können mit der Maus an
              die richtige Stelle gezogen werden. Zum Löschen einer Markierung
              im Editiermodus die Taste "Entfernen" drücken. Ein Klick außerhalb
              der Markierung beendet den Editiermodus.
            </p>

            <Video
              src={areasVideo}
              caption="Rechteckige Markierung hinzufügen"
            />

            <Header as="h2">Eine Punktgruppe hinzufügen</Header>

            <p>
              Für jede*n Verkehrsteilnehmer*in sollen die Bodenkontaktpunkte der
              Person bzw. ihres Fahrzeugs ergänzt werden. Das funktioniert
              ähnlich wie die rechteckigen Markierungen.
            </p>
            <p>
              Nach einem Klick auf das Label Bodenkontaktpunkte{' '}
              <Icon name="pencil alternate" /> können so viele Punkte wie
              notwendig ergänzt werden. Wenn alle Bodenkontaktpunkte eines
              Objekts markiert sind, kann der Vorgang mit einem Klick auf den
              letzten Punkt beendet werden. Die Kontaktpunkte sollen möglichst
              genau markiert werden.
            </p>
            <p>
              Wichtig: Auch Bodenkontaktpunkte, die nicht sichtbar sind, sollten
              da markiert werden, wo sie aus Erfahrung erwartet werden.
            </p>

            <Video src={pointsVideo} caption="Bodenkontaktpunkte hinzufügen" />

            <p>
              Zum Editieren der Bodenkontaktpunkte auf die Linie zwischen den
              Punkten klicken. Diese wird dann gestrichelt angezeigt und
              einzelne Punkte können mit der Maus an die richtige Stelle gezogen
              werden. Ein Klick außerhalb der Markierung beendet den
              Editiermodus.
            </p>
            <p>
              Mit einem einfachen Klick auf einen Punkt wird dieser gelöscht
            </p>
            <p>
              Ein zusätzlicher Punkt kann mit einem Klick auf den
              weiß-transparenten Punkt in der Mitte hinzugefügt werden. Dieser
              Punkt kann dann an die richtige Stelle geschoben werden.
            </p>
            <p>Ein Klick außerhalb der Markierung beendet den Editier-Modus.</p>
            <Video
              src={visibilityVideo}
              caption="Sichtbarkeit der Labels ändern und hinzufügen einer Punktgruppe"
            />
            <p>
              Hinweis: Markierungen einer Art können über das Auge-Symbol (
              <Icon name="eye" />) neben dem Label sicht- und unsichtbar
              gestellt werden. Das kann bei vielen und teilweise
              übereinanderliegenden Objekten hilfreich sein.
            </p>

            <Header as="h2">Teilweise verdeckte Objekte</Header>
            <p>
              Auch teilweise verdeckte Objekte (entweder durch ein anderes
              Objekt oder den Bildrand nicht vollständig sichtbar) können
              gelabeled werden. Die Markierung sollte dann den Bereich abdecken,
              wo das Objekt vermutet wird, selbst wenn dadurch die Markierung
              teilweise außerhalb des Bildes liegt.
            </p>

            <Header as="h2">Dichte Gruppen von Verkehrsteilnehmer*innen</Header>
            <p>
              Manchmal sind Verkehrsteilnehmer*innen so nah beieinander, dass im
              Bild die Einzelobjekte nur schwer auseinanderzuhalten und
              abzugrenzen sind. Als Faustregel gilt: Es sollten nur Objekte
              markiert werden, die sich mit maximal zwei anderen Objekten
              überschneiden.
            </p>

            <Header as="h2">Bearbeitung abschicken</Header>
            <p>
              Wenn alle Markierungen gesetzt sind, können sie durch klicken auf
              die "Next"-Schaltfläche gespeichert werden. Im Anschluss erscheint
              das nächste Bild zur Bearbeitung.
            </p>

            <Header as="h2">Verpixelte Gesichter</Header>
            <p>
              Bei manchen Bildern werden aus Datenschutzgründen die Gesichter
              verpixelt.
            </p>

            <Header as="h2">Kategorien korrekt auswählen</Header>

            <Header as="h3">zu Fuß Gehende:</Header>

            <p>
              Erklärung: Alle Menschen, die zur Fortbewegung alle Füße
              (gleichzeitig oder abwechselnd) am Boden haben. Auch Hüpfen,
              Springen, Laufen, Rennen, usw.
            </p>

            <Header as="h3">Zubehör von zu Fuß Gehenden:</Header>
            <p>
              <ul>
                <li>Rollator</li>
                <li>Kinderwagen</li>
                <li>Handwagen</li>
              </ul>
            </p>

            <Header as="h3">Person, die ein Fahrzeug führt</Header>
            <p>Erläuterung: nur die Person wird markiert</p>

            <Header as="h3">Fahrzeuge</Header>
            <p>
              Erläuterung: Kraftfahrzeuge im Sinne der StVO müssen nicht
              markiert werden. Einzige Ausnahme hier sind S-Pedelecs, da die
              Unterscheidung oft schwer fällt bzw. unmöglich ist, ist es den
              Nutzer*innen freigestellt diese zu markieren, wenn sie erkennen
              oder vermuten, dass es sich um ein solches handelt.{' '}
            </p>
            <p>
              Räder:
              <ul>
                <li>Fahrrad (auch Pedelec und S-Pedelec)</li>
                <li>Lastenrad</li>
                <li>Rad mit Anhänger</li>
                <li>Liegerad</li>
                <li>verkleidetes Rad</li>
              </ul>
            </p>
            <p>
              weitere:
              <ul>
                <li>Roller</li>
                <li>Elektroroller</li>
                <li>Skateboard</li>
                <li>Rollstuhl</li>
              </ul>
            </p>

            <Header as="h3">Bodenkontaktpunkte</Header>
            <p>Erläuterung: keine Kraftfahrzeuge im Sinne der StVO</p>

            <Header as="h3">
              Mini-Umfrage für jedes Bild (nur bei Bedarf auszufüllen)
            </Header>
            <p>
              <ul>
                <li>
                  Es befinden sich Objekte ohne passende Kategorie im Bild
                </li>
                <li>
                  nicht bei allen Datensätzen: Bild ist nicht genug verpixelt
                </li>
                <li>Ich habe das Bild nicht oder nur teilweise bearbeitet</li>
                <li>Es waren mir zu viele Objekte im Bild</li>
              </ul>
            </p>
          </div>
        </DocumentMeta>
      </Menubar>
    );
  }
}
