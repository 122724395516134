import React, { Component } from 'react';
import DocumentMeta from 'react-document-meta';
import { Link } from 'react-router-dom';
import styles from './aboutstyle.css';

import { Header, List, Icon, Label, Segment, Flag } from 'semantic-ui-react';
import Menubar from '../common/Menubar';
import About from './About';

import foerderlogo from './images/BMDV_Fz_2021_WebSVG_de.svg';
import adfclogo from './images/ADFC-Logo_2009_1.svg';
import hlrslogo from './images/hlrslogo.png';
import kitlogo from './images/kitlogo.svg';
import caperevisologo from './images/CapeRevisoLogo.png';

export default class AboutPage extends Component {
  render() {
    return (
      <Menubar active="about">
        <DocumentMeta title="About -- CapeReviso Label Tool">
          <About></About>
        </DocumentMeta>
      </Menubar>
    );
  }
}
