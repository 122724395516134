import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Message } from 'semantic-ui-react';
import Menubar from '../common/Menubar';

export default class OverScreen extends Component {
  render() {
    const { projectId } = this.props.match.params;

    return (
      <Menubar>
        <Message style={{ maxWidth: 600, margin: '100px auto' }}>
          <Message.Header>No images available for labeling</Message.Header>
          <p>
            Either there are no images, or all images are being labeled right
            now. Please come back later.
          </p>
        </Message>
      </Menubar>
    );
  }
}
