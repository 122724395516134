import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Container, Menu, Icon } from 'semantic-ui-react';

export default class Menubar extends Component {
  render() {
    const { active } = this.props;
    return (
      <div style={{ background: '#f7f7f7', minHeight: '100vh' }}>
        <Menu inverted>
          <Container>
            <Menu.Item header>CapeReviso Image Labeling</Menu.Item>
            <Link to="/">
              <Menu.Item active={active === 'home'}>
                <Icon name="home" style={{ marginRight: '5px' }} />
                Home
              </Menu.Item>
            </Link>
            <Link to="/label/5">
              <Menu.Item active={active === 'label'}>
                <Icon name="tag" style={{ marginRight: '5px' }} />
                Start Labeling
              </Menu.Item>
            </Link>
            <Link to="/admin/">
              <Menu.Item active={active === 'admin'}>
                <Icon name="pencil" style={{ marginRight: '5px' }} />
                Admin
              </Menu.Item>
            </Link>
            <Link to="/help_de/" target="_blank">
              <Menu.Item active={active === 'help'}>
                <Icon name="help circle" style={{ marginRight: '5px' }} />
                Help
              </Menu.Item>
            </Link>
            <Link to="/about/">
              <Menu.Item active={active === 'about'}>
                <Icon name="info circle" style={{ marginRight: '5px' }} />
                About
              </Menu.Item>
            </Link>
            <Link to="/feedback/">
              <Menu.Item active={active === 'feedback'}>
                <Icon name="mail" style={{ marginRight: '5px' }} />
                Feedback
              </Menu.Item>
            </Link>
          </Container>
        </Menu>

        <Container>{this.props.children}</Container>
      </div>
    );
  }
}
