import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import About, {
  AboutCapeReviso,
  AboutDeinBeitrag,
  CapeRevisoLogo,
  Foerderlogo,
  Projekt5Logo,
  Projekt3Logo,
} from '../about/About';

import { Header, Container, Menu, Icon } from 'semantic-ui-react';

export default class LandingPage extends Component {
  render() {
    const { active } = this.props;
    return (
      <div style={{ background: '#f7f7f7', minHeight: '100vh' }}>
        <CapeRevisoLogo />
        <AboutCapeReviso />
        <AboutDeinBeitrag />
        <Losgehts />
        <Header as="h3">Schnelles Labeln</Header>
        <ProjektnurBesondere />
        <Header as="h3">Umfangreiches Labeln</Header>
        <ProjectRadfahrer1 />
        <Foerderlogo />
      </div>
    );
  }
}

export class ProjectRadfahrer1 extends Component {
  render() {
    const { active } = this.props;
    return (
      <div>
        <Link to="/label/3">
          {' '}
          <Projekt3Logo />
        </Link>
      </div>
    );
  }
}

export class ProjektnurBesondere extends Component {
  render() {
    const { active } = this.props;
    return (
      <div>
        <Link to="/label/5">
          {' '}
          <Projekt5Logo />
        </Link>
      </div>
    );
  }
}

export class Losgehts extends Component {
  render() {
    return (
      <div>
        <p />
        <Header as="h2">
          {' '}
          <Link to="/help_de" target="_blank">
            Anleitung verstanden?{' '}
          </Link>
          Los gehts!{' '}
        </Header>
        <p>Klicke auf eins der Bilder um zum Labeln zu gelanden.</p>
      </div>
    );
  }
}
