import React, { Component } from 'react';
import DocumentMeta from 'react-document-meta';
import { Link } from 'react-router-dom';

import { Header, List, Icon, Label, Segment, Flag } from 'semantic-ui-react';

import Menubar from '../common/Menubar';

import areasVideo from './tutorial/videos/bike-and-cyclist.ogv';
import pointsVideo from './tutorial/videos/ground-points.ogv';
import visibilityVideo from './tutorial/videos/visibility.ogv';

function Img({ src, caption }) {
  return (
    <figure style={{ textAlign: 'center', margin: '1em 1em' }}>
      <img src={src} alt={caption} />
      <figcaption style={{ color: '#999', fontSize: '12px' }}>
        {caption}
      </figcaption>
    </figure>
  );
}

function Video({ src, caption }) {
  return (
    <figure style={{ textAlign: 'center', margin: '1em 1em' }}>
      <video
        alt={caption}
        autoPlay
        controls
        loop
        muted
        style={{ maxWidth: '100%', border: '1px solid #AAA' }}
      >
        <source src={src} type="video/ogg" />
      </video>
      <figcaption style={{ color: '#999', fontSize: '12px' }}>
        {caption}
      </figcaption>
    </figure>
  );
}

export default class Help extends Component {
  render() {
    return (
      <Menubar active="help">
        <DocumentMeta title="Help -- Label Tool">
          <div
            style={{
              flex: 1,
              paddingBottom: '5em',
              margin: '4rem auto',
              maxWidth: '60rem',
            }}
          >
            <Link to="/help_de">
              <button>
                <Flag name="de" /> Deutsch
              </button>
            </Link>
            <Link to="/help">
              <button>
                <Flag name="gb" /> English
              </button>
            </Link>
            <Header as="h1">How To Label</Header>
            <p>
              The labeling interface presents the user with a canvas to create
              and edit figures overlayed over the image being labeled and the
              list of labels on the left.
            </p>

            <p>
              Each label has a shortcut and a color associated with it. The
              shortcut is usually a number in the 1-9 range. Pressing the
              shortcut or clicking the label entry will activate the drawing
              mode for the label with the given color.
            </p>

            <Header as="h2">Adding a New Bounding Box</Header>

            <p>
              Start by clicking on a label on the left that is marked as a
              bounding box label <Icon name="object ungroup outline" />.
            </p>

            <p>
              After selecting a drawing label, you can start outlining a figure
              by clicking on the segmentation area.
            </p>

            <p>
              To edit a figure click on it, this selects the figure and it will
              be drawn with dashes. You can now move points by dragging them
              with your mouse. When you are done editing the figure, click
              outside to remove the selection.
            </p>

            <Video src={areasVideo} caption="Adding new bounding boxes" />

            <Header as="h2">Adding pointsets</Header>

            <p>
              For each participant in traffic, add a set of ground contact
              points. This is similar to a figure as above.
            </p>
            <p>
              Click the corresponding label marked with{' '}
              <Icon name="pencil alternate" /> and add as many points as
              required.
            </p>
            <p>
              Note: Please also add ground contact points that are occluded by
              other objects in the image in the place you expect them.
            </p>
            <p>
              When you're done adding points to a single set, click again on the
              last created point to finish the drawing.
            </p>

            <Video src={pointsVideo} caption="Adding a new pointset" />

            <p>
              Editing a pointset works by clicking it, it will be drawn with
              dashed lines again while editing.
            </p>
            <p>To delete a point, click on it once.</p>
            <p>
              To add a new point, click on the transparent white point in the
              middle, and drag it to where you need it.
            </p>
            <p>To finish editing, click outside the pointset.</p>
            <Video
              src={visibilityVideo}
              caption="Changing the visibility of the labels, then editing a pointset"
            />
            <p>
              Hint: You can toggle the visibility of all drawings that
              correspond to a label with the eye icon (<Icon name="eye" />) next
              to the label. This can be helpful when moving points to their
              exact location.
            </p>

            <Header as="h2">Submitting the Labeled Image</Header>
            <p>
              After you are done labeling the image, click the "Next" button on
              the sidebar to save your contribution and continue to another
              image.
            </p>

            <Header as="h2">Partially occluded objects</Header>
            <p>
              When encountering partially occluded objects (either by another
              object or the edge of the image) you can still label it. The label
              should then cover the whole area where you expect the object to
              be, even if your label exceeds the borders of the image.
            </p>

            <Header as="h2">Labeling of dense groups</Header>
            <p>
              Sometimes objects in groups are so close to each other that the
              individual object can hardly be identified. As a rule of thumb,
              only objects with that overlap with a maximum of two other objects
              need to be labeled.
            </p>
          </div>
        </DocumentMeta>
      </Menubar>
    );
  }
}
