import React, { Component, Fragment } from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';

import LabelHome from './label/LabelHome';
import LabelingLoader from './label/LabelingLoader';
import OverScreen from './label/OverScreen';
import AdminApp from './admin/AdminApp';
import Help from './help/Help';
import Help_De from './help/Help_De';
import AboutPage from './about/AboutPage';

class App extends Component {
  render() {
    if (process.env.REACT_APP_DEMO) {
      const props = {
        match: {
          params: {
            projectId: 'demo',
            imageId: 1,
          },
        },
        history: {
          replace: () => {},
          push: () => {},
          goBack: () => {},
        },
      };
      return <LabelingLoader {...props} />;
    }

    return (
      <Router>
        <Fragment>
          <Route exact path="/home" component={LabelHome} />
          <Route exact path="/" component={LabelHome} />
          <Route path="/admin" component={AdminApp} />
          <Route path="/help" component={Help} />
          <Route path="/help_de" component={Help_De} />
          <Route path="/about" component={AboutPage} />
          <Route exact path="/label" component={LabelingLoader} />
          <Route exact path="/label/:projectId" component={LabelingLoader} />
          <Route
            path="/legal-info"
            component={() => {
              window.location.href = 'https://www.hlrs.de/contact/legal-info/';
              return null;
            }}
          />
          <Route
            path="/feedback"
            component={() => {
              window.location.href =
                'https://capereviso.hlrs.de/2022/05/13/feedback-zum-labelingtool/';
              return null;
            }}
          />
          <Route
            path="/capereviso-blog"
            component={() => {
              window.location.href = 'https://capereviso.hlrs.de/das-projekt/';
              return null;
            }}
          />
          <Route
            exact
            path="/label/:projectId/:imageId"
            render={props =>
              props.match.params.imageId === 'over' ? (
                <OverScreen {...props} />
              ) : (
                <LabelingLoader {...props} />
              )
            }
          />
        </Fragment>
      </Router>
    );
  }
}

export default App;
